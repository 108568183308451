<template>
    <div class="modal-basic">
        <div class="head">
            <i class="material-icons" @click="onClickClose">close</i>
        </div>
        <div class="body">
            <div class="message-info">
                <div class="title" v-html="title" />
                <div class="content" v-html="content" />
            </div>
            <TextareaWithX
                :placeholder="`진심을 담아 메시지를 작성해 주세요! 복붙 메시지는 수락 받을 확률이 낮아요.`"
                class="m-t-24 m-b-20"
                v-model="message"
            />
            <BottomButton @click="onClickLike" :label="'호감 보내기'" />
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ModalLikeMessage',
    props: ['options'],
    data: () => ({
        message: '',
    }),
    mounted() {
        // this.$registerBackHandler(() => {})
    },
    beforeDestroy() {
        // this.$unregisterBackHandler()
    },
    computed: {
        title() {
            return this.options.title
        },
        content() {
            return this.options.content
        },
        targetId() {
            return this.options.targetId
        },
    },
    methods: {
        async onClickClose() {
            const idx = await this.$modal.basic({
                body: '이미 사용한 하트는 복원할 수 없습니다. 그래도 호감 보내기를 취소하시겠습니까?',
                buttons: [
                    {
                        label: '호감 보내기',
                        class: 'btn-primary',
                    },
                    {
                        label: '보내기 취소',
                        class: 'btn-default',
                    },
                ],
            })

            if (idx) {
                this.$emit('close')
            }
        },
        async sendLikeMessage() {
            try {
                const res = await userService.sendRequest({
                    target_id: this.targetId,
                    message: this.message,
                    like_type: 'normal',
                })
                this.$toast.success(`호감을 보냈어요!`)
                this.$loading(true)
                // await this.$store.dispatch('loadBadges')
                this.$emit('close', res)
                this.$loading(false)
            } catch (e) {
                this.$modal.basic({
                    title: e.data.title,
                    body: e.data.msg,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                // this.$emit('close')
            }
        },
        async onClickLike() {
            if (!this.message) {
                const res = await this.$modal.basic({
                    body: '메시지를 입력하면 수락률이 높아집니다. 메시지 없이 보내시겠습니까?',
                    buttons: [
                        {
                            label: '그냥 보내기',
                            class: 'btn-default',
                        },
                        {
                            label: '작성하기',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (res === 0) {
                    await this.sendLikeMessage()
                }
            } else {
                const res = await this.$modal.basic({
                    body: '호감을 보냅니다.',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (res) await this.sendLikeMessage()
            }
        },
    },
}
</script>

<style scoped lang="scss">
.modal-basic {
    min-width: 320px;
    overflow-y: hidden;
}
.head {
    position: relative;
    float: right;
    padding: 0;

    i {
        position: absolute;
        right: 0;
        top: 0;
    }
}
.body {
    padding: 0;

    .title {
        @include f-medium;
        font-size: 20px;
        margin-bottom: 16px;
    }
    .content {
        font-size: 13px;
        color: $grey-09;
        @include spoqa-f-regular;
    }
}
::v-deep textarea {
    height: 296px;
    margin: 0 !important;

    &::placeholder {
        font-size: 15px;
        line-height: 24px;
    }
}

::v-deep .bottom-button {
    position: static;
    padding: 0;

    button {
        background: $purple-primary;
        color: white;
    }
}
</style>
